body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6c757d;
  text-align: left;
  background-color: #ffffff; }

a { text-decoration: none !important;}

*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 7px;
  background-clip: padding-box;
  border: 3px solid transparent;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #929292;
}
